export default {
    name: "Campaign",
    created(){
        this.$nextTick(function(){
            this.CampaignDataLoad()
        })
    },
    mounted() {
    },
    methods: {
        CampaignDataLoad() {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetDeployedCampaign",
                data: { token: "webkey" },
                success: function (response) {
                    console.log(response);
                    if (response.text.length >0 && response.text[0].image != "") {
                       $(".image-show").css({ 'display': 'block' });
                       var img = "<img src='"+response.text[0].image+"' alt=''>";
                       $(".campaign-body").html(img);
                    }else{
                       $(".image-noshow").css({ 'display': 'block' });
                    }
                },
                error: function (XMLHttpRequest) {
                }
            });
        }
    }
  };